<template>
  <div class="academic-panel">
    <div class="container py-5 px-3">
      <div class="is-flex is-justify-content-space-between">
        <b-breadcrumb
          align="is-left"
          size="is-small"
        >
          <b-breadcrumb-item tag='router-link' to="/perfil">Perfil</b-breadcrumb-item>
          <b-breadcrumb-item tag='router-link' to="/verCertificados" active>Certificados</b-breadcrumb-item>
        </b-breadcrumb>
      </div>
      <div class="columns mt-3">
        <div class="column is-4">
          <h1 class="is-size-5 has-text-grey mb-4">Mis certificados</h1>
          <div class="panel-options-container has-background-white p-3 mt-4">
            <span v-for="(item, index) in allCertificates" :key="index">
              <h2 class="is-size-7">{{ item.title }}</h2>
              <hr>
            </span>
          </div>
        </div>
        <div class="column is-8">
          <div v-for="(item, index) in allCoursesFormNames" class="has-background-white has-radius mb-3 p-3 is-flex is-justify-content-space-between" :key="index">
            <h1 class="is-size-6">{{ item.name }}</h1>
            <h2 class="is-size-7 mt-1 has-text-grey">{{ item.institution }}</h2>
            <div class="is-flex is-justify-content-space-between">
              <button class="button is-small is-link has-radius--5"
                      @click="$router.push({ name: 'EditFormCourse', params: {courseId: item.id }})">Editar
              </button>
              <button class="button is-small is-outlined is-link ml-3 has-radius--5"
                      @click="deleteFormEnrollCourse(item.id)">Eliminar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import coursesService from "@/services/Courses";
import certificateService from "@/services/Certificate";

export default {
  name: "ViewAllCertificatesProfile",
  data() {
    return {
      allCertificates: []
    }
  },
  methods: {
    async getAllCertificates() {
      try {
        this.allCertificates = await certificateService.getCertificate();
        console.log('getAllCoursesForm', this.allCertificates);
      } catch (e) {
        console.log('e', e);
      }
    },
    async getAllGeneralCourses() {
      try {
        this.allGeneralCourses = await coursesService.getGeneralCoursesList();
      } catch (e) {
        console.log('e', e);
      }
    },
  },
  mounted() {
    this.getAllCertificates();
  }
}
</script>

<style scoped lang="scss">
.academic-panel {
  background: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)),
  url(../../assets/img/academico-coltejer-v2.jpg) no-repeat fixed;
  background-size: cover;
  min-height: 100vh;
  width: 100%;

  a:hover {
    color: #ae3e97 !important;
  }

  hr {
    height: 1px !important;
    margin: 5px 0px;
    background-color: #D5D5D5;
  }

  .has-radius {
    border-radius: 8px;
  }

  .has-radius--5 {
    border-radius: 5px;
  }

  .panel-options-container {
    border-radius: 8px;
    max-height: 50vh;
    height: auto;
    overflow-y: auto;

    a:hover {
      color: #ae3e97 !important;
    }

  }
}

</style>
