import {nodeInstance} from "@/plugins/axios";

const certificateService = {};

certificateService.getCertificate = () => nodeInstance.get(`/api/certificates`);
certificateService.getCertificateById = (id) => nodeInstance.get(`/api/certificates/${id}`);

certificateService.createCertificate = (params) => {
  return nodeInstance.post('/api/certificate/create', params );
}

export default certificateService;
